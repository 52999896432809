// Here you can add other styles

.btn-primary {
    background-color: #5367F9;
    border-color: #5367F9;
}

.sidebar {
    --cui-sidebar-width: 22rem;
    background: #ffffff;
}

.sidebar:not(.sidebar-end)~* {
    --cui-sidebar-occupy-start: 22rem;
}

.sidebar-brand {
    background: transparent;
    padding: 20px 0px;
}


.sidebar-nav::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.sidebar-nav::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
}

.sidebar-nav::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.sidebar-nav .nav-link {
    color: #000000;
}

.sidebar-nav .nav-icon,
.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link:hover .nav-icon {
    color: #5367F9;
}

.sidebar-nav .nav-group.show .nav-group-toggle {
    color: #000000;
}

.sidebar-nav .nav-group.show {
    background: transparent;
}

.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link.active .nav-icon {
    color: #ffffff;
    background: #5367F9;
}

.sidebar-nav .nav-title {
    color: #BAC0C7;
}

.sidebar-nav .nav-group-toggle::after {
    filter: invert(100%);
}


html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
    padding-left: 6rem;
}

.sidebar form {
    margin: 20px 30px 30px;
}

.sidebar .form-control {
    border-radius: 50px;
    line-height: 2;
    padding: 5px 30px;
    border-color: #5367F9;
}

.sidebar-narrow-unfoldable:not(:hover) form {
    display: none;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: 100%;
}

.nav-pills .nav-link {
    background: #5DDE85;
    color: #ffffff;
    padding: 3px 15px;
}

.nav-pills .nav-link::after {
    display: none;
}

.nav-pills .nav-link .icon {
    margin-left: 10px;
    width: 15px;
}

.nav-pills .show>.nav-link {
    background: #5DDE85;
    color: #ffffff;
}

.table> :not(caption)>*>* {
    vertical-align: middle;
    padding: 1rem 0.5rem;
    border-bottom-width: 0px;
}

.bg-login {
    background: url('../assets/images/login-bg.svg'), #F0F0F0;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
}

.bg-login .card {
    border: 0;
    background: transparent;
}

.loginBox {
    background: #FAFAFA;
    box-shadow: -17px -6px 28px #e2e2e2;
    padding: 50px;
    border-radius: 40px;
}

.form-label {
    font-weight: 500;
}

.health-tab .nav-tabs .nav-link {
    padding: 0px 15px 15px;
    font-weight: 600;
    color: #000000;
    border: 0;
}

.health-tab .nav-tabs .nav-link:hover,
.health-tab .nav-tabs .nav-link:focus {
    border: 0;
    cursor: pointer;
}

.health-tab .nav-tabs .nav-link.active,
.health-tab .nav-tabs .nav-item.show .nav-link {
    border: 0;
    border-bottom: 3px solid #5367F9;
    color: #5367F9;
}

.health-tab .tab-content {
    padding: 30px 20px;
}

.dividerOne {
    background: #d8dbe0;
    height: 1px;
    margin: 20px 0px 10px;
}

.footer {
    background: #ffffff;
}

.hideifempty:empty {
    display: none !important;
}
.ry_popup {
    position: fixed;
    inset: 0px;
    background: rgb(0 0 0 / 70%);
    z-index: 999999;
}
.readonlySection {
    position: relative;
}
.readonlySection .spanry {
    position: absolute;
    inset: 0px;
    text-align: center;
}
.RYBrandLogo {
    max-width: 100%;
    max-height: 110px;
    object-fit: contain;
    // transition: height 10s ease;
    transition: width 1s, height 1.5s;
}
// .RYBrandLogo:hover {
//     // height: auto;
//     // height: 80px;
// }
.ry_popup_content {
    position: fixed;
    background: rgb(255, 255, 255);
    padding: 10px;
    margin: auto;
    border-radius: 5px;
    overflow: hidden auto;
    inset: 0px;
    z-index: 999999;
    max-width: 50%;
    max-height: 50%;
}
.ry_popup_bg {
    position: fixed;
    background: rgba(15, 15, 15, 0.8);
    margin: auto;
    overflow: auto;
    inset: 0px;
    z-index: 888888;  
}
.table-responsive {
    // min-height: 270px;
    min-height: 100vh
}

@media print {
    .wrapper {
        padding-left: 0px !important;
    }
    .dontPrint, .sidebar-fixed {
       visibility: hidden !important;
       display: none !important;
    }
    .pdfPrint-50 {
       float: none;
       display: inline-block;
       vertical-align: top;
       width: 50%;
       padding: 0 20px 0 0;
    }
 }
 .fontReport {
    font-size: 13px;
    border: 1px solid #f5f5f5;
 }
 .print-only-content {
    display: none;
 }
 @media print {
    .print-only-content {
      display: block;
    }
}
.rbc-agenda-time-cell {
    display: none !important;
}
.rbc-agenda-table thead {
    display: none !important;
}
.DBLSAniData {
    border: 1px solid #f5f5f5;
    margin-bottom: 15px;
    padding: 5px 10px;
}
.DBLSAniDataItem {
    border: 1px solid #2eb86154;
    padding: 10px 10px 0px 10px;
    margin-bottom: 20px;
}
.mediaStop {
    color: #cc0000;
    background: #cc0000;
    width: 0.75rem !important;
    height: 0.75rem !important;
}
.usr_status {
    margin-left: 5px;
    max-width: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body { font-family: "Montserrat", sans-serif !important; font-optical-sizing: auto; font-style: normal; }
.oneLineText {
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    height: 1.5em;
}
.savedDraftRow {
    border: 1px solid #dedede;
    padding: 10px 20px;
    margin-bottom: 15px;
}
.savedDraftAction  {
    float: right;
}
.clearBoth {
    clear: both;
}
.closeXBtn {
    float: right;
    text-decoration: none;
    border: 1px solid #cc0000;
    padding: 0px 5px;
}
.a-deco-none {
    text-decoration: none;
}
